<template>
  <div class="container">
    <div class="usable-card">
      <div class="title">
        <div class="left">可用卡券</div>
      </div>
      <div class="card-box">
        <div class="card" v-for="(item,i) in copList" :key="i">
          <div class="l-img">
            <div class="num">¥{{  item.money}}</div>
            <div class="text">{{ item.name }}</div>
            <div class="round"></div>
          </div>
          <div class="r-info">
            <div class="info-left">
              <div class="r-title">
                {{ item.shiyong }}
              </div>
              <div class="introduce">
                {{ item.server }}
              </div>
              <div class="time">
                有效期至：{{ item.endtime | timeform(false) }}
              </div>
            </div>
            <div class="info-right" @click="lookInfo(item)">
              查看详情 <span class="el-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      <empty title="暂无卡券" v-if="copList.length == 0"></empty>
      </div>
    </div>
    <div class="failure-card usable-card">
      <div class="title">
        <div class="left">失效卡券</div>
      </div>
      <div class="card-box">
        <div class="card" v-for="(item,i) in NocopList" :key="i">
          <div class="l-img">
            <div class="num">¥{{ item.money}}</div>
            <div class="text">{{ item.name }}</div>
            <div class="round"></div>
          </div>
          <div class="r-info">
            <div class="info-left">
              <div class="r-title">
                {{ item.shiyong }}
              </div>
              <div class="introduce">
                {{ item.server }}
              </div>
              <div class="time">
                有效期至：{{ item.endtime | timeform(false) }}
              </div>
            </div>
            <div class="info-right">
              <!-- <span v-if="i<=1" class="iconfont icon-yishiyong"></span> -->
              <span  class="iconfont icon-yiguoqi"></span>
            </div>
          </div>
        </div>
      <empty title="暂无卡券" v-if="NocopList.length == 0"></empty>
      </div>
    </div>
    <el-dialog :visible.sync="cardDetailShow" width="40%" :show-close="false" :before-close="handleClose">
      <template slot="title">
        <div class="dia-title">
          <div class="num">¥{{ lookitem.money}}</div>
          <div class="text">{{ lookitem.name }}</div>
          <div class="round"></div>

        </div>
      </template>
      <el-container style="height:300px;background-color:#fff;">
        <el-main>
          <div class="detail">
            <p>·适用门店：{{ lookitem.shiyong }}</p>
            <p>·有效期至：{{ lookitem.endtime | timeform(false) }}</p>
            <p>·可用项目：{{ lookitem.server }}</p>
          </div>
        </el-main>
      </el-container>
      <div class="shadow"></div>
      <div class="el-icon-circle-close" @click="handleClose"></div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        cardDetailShow: false,
        copList:[],
        NocopList:[],
        lookitem:{}
      }
    },
    computed:{
      ...mapState(['vipuser'])
    },
    created () {
      this.getcopList()
      this.getNocopList()
    },
    methods: {
      lookInfo(item){
        this.lookitem = item
        this.cardDetailShow=true
      },
      //获取可用优惠券列表
      getcopList(){
        this.$http.post('api/store/storeuser/usercouponlist',{
          user_id:this.vipuser.id,
          type:1
        }).then(res =>{
          this.copList = res.data.data
        })
      },
       //获取不可用优惠券列表
       getNocopList(){
        this.$http.post('api/store/storeuser/usercouponlist',{
          user_id:this.vipuser.id,
          type:2
        }).then(res =>{
          this.NocopList = res.data.data
        })
      },
      handleClose() {
        this.cardDetailShow = false
      }
    },
  }

</script>

<style lang="less" scoped>
  .usable-card {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 50px;
      color: #666;
      font-weight: bold;
    }

    .card-box {
      .card {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        box-shadow: 0 0 2px #ccc;
        margin-bottom: 15px;


        .l-img {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-width: 100px;
          height: 100px;
          background-color: #ffa94c;
          color: #fff;
          text-align: center;
          overflow: hidden;

          .num {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .round {
            position: absolute;
            top: 50%;
            left: -9px;
            transform: translateY(-50%);
            width: 15px;
            height: 15px;
            background-color: #fff;
            border-radius: 100%;
          }
        }

        .r-info {
          width: 100%;
          padding-left: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .info-left {

            .r-title,
            .introduce {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .r-title {
              font-size: 15px;
              font-weight: bold;
              color: #666;
            }

            .introduce {
              font-size: 12px;
              color: #999;
              margin: 8px 0 15px;
            }

            .time {
              font-size: 13px;
              color: #999;
            }
          }

          .info-right {
            color: #999;
            padding-right: 15px;
          }
        }
      }
    }


  }

  .failure-card {
    color: #666;

    .card-box {
      .card {
        .l-img {
          background-color: #ccc;
        }

        .r-info {
          .info-right {
            padding-right: 15px;

            .iconfont {
              font-size: 60px;
              color: #ccc;

            }
          }
        }
      }
    }

  }

  /deep/ .el-dialog {
    position: relative;
    border-radius: 10px;
    min-width: 400px;
    max-width: 500px;

    .shadow {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #e9e9e9;
      width: 100%;
      height: 100%;
      transform: rotate(-10deg);
      border-radius: 10px;

    }

    .el-dialog__header {
      padding: 0;


      .el-dialog__headerbtn {
        top: 14px;
      }

    }

    .dia-title {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 0;
      text-align: center;
      background-color: #ffa94c;
      color: #fff;
      border-bottom: 4px dotted #fff;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      height: 120px;


      .num {
        margin-top: 30px;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .round {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-radius: 100%;
      }
    }

    .el-dialog__body {
      height: 300px;
      padding: 10px;
    }

    .detail {
      color: #999;
    }

    .el-icon-circle-close {
      position: absolute;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 20px;
    }


  }

</style>
